import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

if (document.location.href.indexOf("aftersales.qotto.app") < 0) {

  alert("Vous allez être redirigé vers la nouvelle adresse :\n \"https://aftersales.qotto.app\".");
  document.location.href = "https://aftersales.qotto.app";

} else {

  ReactDOM.render(<App />, document.getElementById("root"));
  registerServiceWorker();

}
